import React from "react";
import { graphql, PageProps } from "gatsby";

import Hero from "@shared/hero";
import SEO from "@shared/seo";
import { Query, SanityWhatWeDoConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import {
  FullWithImageBlock,
  GetInTouchBlock,
  LeftRightBlock,
  SupportBlock,
  TeamBlock,
  ValueBlock,
} from "@global";

interface Data extends Query {
  altRegions: SanityWhatWeDoConnection;
}
interface Props extends PageProps {
  data: Data;
  pageContext: { iban: string };
}

export default function WhatWeDoPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityWhatWeDo;
  const {
    whatWeDoHero,
    seo,
    leftRightBlock,
    getInTouch,
    supportersInfo,
    partnerInfo,
    teamInfo,
    valueInfo,
    fullWidthImageBlock,
  } = nodes[0];
  const alternateSlugs = data.altRegions.nodes.map((alt) => alt.region);
  useRegionChanged("what-we-do", pageContext.iban);

  return (
    <div>
      <SEO seoData={seo} slug="what-we-do" alternateRegions={alternateSlugs} heroData={whatWeDoHero} />
      <Hero heroData={whatWeDoHero} />
      {leftRightBlock?.map((data) => {
        if (data == null) {
          return null;
        }
        return (
          <LeftRightBlock data={data} key={data?._key} margin="62px 0 82px 0" />
        );
      })}
      {getInTouch && <GetInTouchBlock data={getInTouch} />}
      {supportersInfo && <SupportBlock data={supportersInfo} />}
      {valueInfo && <ValueBlock data={valueInfo} />}
      {teamInfo && <TeamBlock data={teamInfo} />}
      {partnerInfo && <SupportBlock partnerBlock data={partnerInfo} />}
      {fullWidthImageBlock && <FullWithImageBlock data={fullWidthImageBlock} />}
    </div>
  );
}

export const query = graphql`
  query WhatWeDoPageQuery($iban: String) {
    allSanityWhatWeDo(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        whatWeDoHero {
          ...sanityHero
        }
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        leftRightBlock {
          ...sanityLeftRightBlock
        }
        getInTouch {
          ...sanityGetInTouchBlock
        }
        supportersInfo {
          ...sanityPartnerBlock
        }
        partnerInfo {
          ...sanityPartnerBlock
        }
        teamInfo {
          ...sanityTeamBlock
        }
        valueInfo {
          ...sanityTeamBlock
        }
        fullWidthImageBlock {
          ...sanityFullWidthImageBlock
        }
      }
    }
    altRegions: allSanityWhatWeDo {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
